<template>
	<div id="apps">
		
		<div class="text">
			<div>敬 请 期 待!</div>
			<div class="small">需要使用该应用，请联系客服(17380566578)</div>
		</div>
	</div>
</template>

<script>
import { eltips } from '../../util/util.js';
export default {
	name: 'apps',
	data() {
		return {
			
		};
	},
	created() {
		
	},
	methods:{
		
	}
};
</script>



<style scoped lang="less">
	#apps{
		width: 100%;
		height: 100%;
		line-height: 100%;
		position: fixed;
		left: 0;
		top: 0;
		.text{
			width: 100%;
			position: absolute;
			left: 0;
			top: 50%;
			font-size: 40px;
			font-weight: bold;
			text-align: center;
			margin-top: -80px;
			.small{
				margin-top: 60px;
				font-size: 24px;
			}
		}
	}
</style>
